@import "~antd/dist/antd.css";

body {
  color: #37383c;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Helvetica Neue", "Ubuntu", sans-serif;
  font-size: 14px;
  font-stretch: normal;
  font-variant-ligatures: none;
  font-weight: 400;
  letter-spacing: -0.1px;
  line-height: 1.4;
  margin: 0;
  padding: 0;
}
#root {
  height: 100vh;
}
hr { 
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
a,
a:hover {
  text-decoration: none;
}
.ant-table {
  font-size: 13px;
}
.ant-table-tbody > tr > td {
  padding: 8px;
}
.ant-table-thead > tr > th {
  padding: 8px;
}
/* .ant-table.ant-table-bordered thead > tr > th {
  background: red;
} */
.ant-layout-header {
  padding-right: 2rem;
  padding-left: 2rem;
  height: 49px;
  line-height: normal;
  background-color: #f7f9fc;
  user-select: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
}

.notify-option-item {
  display: flex;
  flex-direction: row;
  margin-top: 4px;
}

.notify-option-item .ant-avatar {
  display: flex;
}
.notify-option-item .info {
  display: flex;
  flex-direction: column;
}

.notify-option-item .info .name {
  font-size: 15px;
  color: #333;
  margin-bottom: 5px;
}
.notify-option-item .info .item {
  display: flex;
  flex-direction: row;
  color: #333;
  line-height: 20px;
}
.notify-option-item .info .item .live {
  background: red;
  padding: 0 5px;
  border-radius: 4px;
  color: #fff;
  font-weight: 500;
  margin-right: 5px;
}

.form-upload-image {
  width: 128px;
  height: 128px;
}
.form-upload-image > div {
  width: 128px !important;
  height: 128px !important;
}

